import firebase from 'firebase/app';
import 'firebase/remote-config';

const apiKey = process.env.REACT_APP_FIREBASE_API_KEY;
const appId = process.env.REACT_APP_FIREBASE_APP_ID;
const authDomain = process.env.REACT_APP_FIREBASE_AUTH_DOMAIN;
const databaseURL = process.env.REACT_APP_FIREBASE_DATABASE_URL;
const messagingSenderId = process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID;
const projectId = process.env.REACT_APP_FIREBASE_PROJECT_ID;
const storageBucket = process.env.REACT_APP_FIREBASE_STORAGE_BUCKET;
const measurementId = process.env.REACT_APP_FIREBASE_MEASUREMENT_ID;

const firebaseConfig = {
  apiKey,
  appId,
  authDomain,
  databaseURL,
  messagingSenderId,
  projectId,
  storageBucket,
  measurementId
};

const firebaseInit = () => {
  firebase.initializeApp(firebaseConfig);

  const remoteConfig = firebase.remoteConfig();

  /**
   * The minimumFetchIntervalMillis represents the time the cache will be still
   * valid on device, the default value is 12 hours we changed it to be
   * 5 minutes, which means that when we enable it, the clients will try to
   * have the config updated every 5 minutes
   */
  remoteConfig.settings.minimumFetchIntervalMillis = 300000;

  remoteConfig.defaultConfig = {
    enable_synchronous_package_scan: false
  };
};

export default firebaseInit;
