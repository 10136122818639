import React, { createContext, useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase/app';
import 'firebase/remote-config';
import camelcaseKeys from 'camelcase-keys';
import {
  getSplittedValuesFromFS,
  isFeatureSwitchEnabledForValue,
  getRemoteConfigJSON
} from './utils';

const FirebaseContext = createContext();

function FirebaseConfigProvider({ children }) {
  const remoteConfig = firebase.remoteConfig();
  const [config, setConfig] = useState({});
  const value = useMemo(() => ({ config }), [config]);

  useEffect(() => {
    let unmounted = false;

    (async () => {
      await remoteConfig.fetchAndActivate();

      const fsInSnakeCase = remoteConfig.getAll();
      const fs = camelcaseKeys(fsInSnakeCase);
      if (!unmounted) setConfig(fs);
    })();

    return () => {
      unmounted = true;
    };
  }, [remoteConfig]);

  return (
    <FirebaseContext.Provider value={value}>
      {children}
    </FirebaseContext.Provider>
  );
}

FirebaseConfigProvider.propTypes = { children: PropTypes.node.isRequired };

export const errorMessage =
  'useFeatureSwitch hook must be used inside FirebaseConfigProvider.';

export const remoteConfigErrorMessage =
  'useRemoteConfig hook need a default value.';

function useFeatureSwitch(featureSwitch) {
  const fsContext = React.useContext(FirebaseContext);

  if (!fsContext) throw new Error(errorMessage);

  if (!('config' in fsContext)) return false;

  const fs = fsContext.config;

  if (featureSwitch in fs) {
    const booleans = { true: true, false: false };
    const value = fs[featureSwitch]._value;

    if (value in booleans) {
      return booleans[value];
    }

    return value;
  }

  return false;
}

function useRemoteConfig(remoteConfigFlag, defaultValue) {
  const fsContext = React.useContext(FirebaseContext);

  if (!fsContext) throw new Error(errorMessage);

  if (!defaultValue) throw new Error(remoteConfigErrorMessage);
  if (!('config' in fsContext)) return defaultValue;

  const configs = fsContext.config;
  if (remoteConfigFlag in configs) {
    return getRemoteConfigJSON(configs[remoteConfigFlag]._value, defaultValue);
  }

  return defaultValue;
}

export default FirebaseConfigProvider;
export {
  useFeatureSwitch,
  FirebaseContext,
  getSplittedValuesFromFS,
  isFeatureSwitchEnabledForValue,
  useRemoteConfig
};
