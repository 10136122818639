import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import OfferCard from 'view/molecules/offer-card';
import getOffer from 'infra/services/get-offer';
import Loading from 'view/molecules/loading';
import ErrorAlert from 'view/molecules/error-alert';
import { Box } from '@material-ui/core';
import acceptOffer, { acceptOfferRest } from 'infra/services/accept-offer';
import { registerAbortLoading } from 'infra/services/analytics';
import { parseOfferProtoToOfferCard } from 'infra/services/parsers';
import {
  onAcceptOffer,
  onRejectOffer,
  openDeepLink,
  isFromNewHome,
  onCloseOfferError
} from 'infra/services/app-bridge';
import { useFeatureSwitch } from '@loggi/firebase-feature-switches';
import featureSwitches, {
  featureSwitchEnabledForDriverLMC
} from 'operations/feature-switches';
import TEXT, { OFFER_CHANNEL_PUSH } from 'view/pages/offers/constants';
import OfferDetails from 'view/pages/offer-details/index';

/**
 * Offer channel send when offer mutation has been called
 */
const offerChannel = 'drive_thru';

function OfferScreen({
  getOfferService,
  acceptOfferService,
  acceptOfferMutationService,
  additionalOnAcceptAction,
  shouldOpenDeepLinkOnAccept
}) {
  const { offerId } = useParams();
  const history = useHistory();
  const { goToOfferDetails } = history?.location?.state || {};

  const [offer, setOffer] = useState(null);
  const [offerLoading, setOfferLoading] = useState(true);
  const [error, setError] = useState('');

  const lmcsEnabledToCallAllocation = useFeatureSwitch(
    featureSwitches.callAcceptOfferDirectlyPush
  );
  const ignoreProxy = featureSwitchEnabledForDriverLMC(
    lmcsEnabledToCallAllocation
  );

  const onGraphqlRequestError = responseError => {
    let message = TEXT.UNDETERMINED_ERROR;
    if ('errors' in responseError && responseError.errors.length) {
      message = responseError.errors[0].message;
    }
    setError(message);
  };

  const onAccept = async (itineraryId, demandId, id) => {
    setOfferLoading(true);

    if (itineraryId) {
      acceptOfferMutationService({ itineraryId, offerChannel })
        .then(() => {
          onAcceptOffer();
          if (shouldOpenDeepLinkOnAccept) {
            openDeepLink();
          }
        })
        .catch(responseError => {
          onGraphqlRequestError(responseError);
        })
        .finally(() => setOfferLoading(false));

      return;
    }

    try {
      await acceptOfferService(id, demandId, OFFER_CHANNEL_PUSH, ignoreProxy);
      onAcceptOffer();
      additionalOnAcceptAction(demandId);
      if (shouldOpenDeepLinkOnAccept) {
        openDeepLink();
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setOfferLoading(false);
    }
  };

  const goBack = () => {
    if (
      isFromNewHome() ||
      window.location.href.startsWith(process.env.REACT_APP_DRIVERS_URL)
    ) {
      history.goBack();
    } else {
      onCloseOfferError();
    }
  };

  const handleCloseLoading = () => {
    registerAbortLoading('offer-screen');

    goBack();
  };

  const onReject = () => {
    onRejectOffer();

    goBack();
  };

  const onCloseError = () => {
    setError('');

    goBack();
  };

  const handleUpdateOffer = () => {
    async function getOfferCard() {
      setOfferLoading(true);

      try {
        const response = await getOfferService(offerId);
        const offerCard = response?.offerCard
          ? parseOfferProtoToOfferCard(response.offerCard)
          : undefined;
        setOffer(offerCard);
      } catch (err) {
        setError(err?.message || err);
      } finally {
        setOfferLoading(false);
      }
    }

    getOfferCard();
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(handleUpdateOffer, [getOfferService]);

  if (error) {
    return <ErrorAlert error={error} onClose={onCloseError} />;
  }

  if (offerLoading) {
    return <Loading visibleBackButton onClose={handleCloseLoading} />;
  }

  return (
    <Box p={2} mt={1}>
      {offer &&
        (!goToOfferDetails ? (
          <OfferCard
            offer={offer}
            onAccept={onAccept}
            onReject={onReject}
            hasReject={!offer.directAttribution}
          />
        ) : (
          <OfferDetails
            offer={offer}
            onAccept={() => {
              onAccept(offer.itineraryId, offer.demandId, offer.id);
            }}
            onBack={() => {
              history.goBack();
            }}
          />
        ))}
    </Box>
  );
}

OfferScreen.propTypes = {
  getOfferService: PropTypes.func,
  acceptOfferService: PropTypes.func,
  acceptOfferMutationService: PropTypes.func,
  additionalOnAcceptAction: PropTypes.func,
  shouldOpenDeepLinkOnAccept: PropTypes.bool
};

OfferScreen.defaultProps = {
  getOfferService: getOffer,
  acceptOfferService: acceptOfferRest,
  acceptOfferMutationService: acceptOffer,
  additionalOnAcceptAction: () => ({}),
  shouldOpenDeepLinkOnAccept: true
};

export default OfferScreen;
